<template>
  <div>
      <el-dialog title="收货地址" :visible.sync="dialogFormVisible1">
        <el-form :model="form">
            <el-form-item label="活动名称" :label-width="formLabelWidth">
             <el-input v-model="form.oldpassword" autocomplete="off"></el-input>
            </el-form-item>
             <el-form-item label="活动名称" :label-width="formLabelWidth">
             <el-input v-model="form.newpassword" autocomplete="off"></el-input>
            </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          
            <el-button type="primary" @click="sure()">确 定</el-button>
        </div>
        </el-dialog>
  </div>
</template>

<script>
export default {
     props:['chufa'],
    data() {
      return {
        dialogFormVisible1: false,
        form: {
       oldpassword:'',
            newpassword:''
        },
        formLabelWidth: '120px'
      };
    },
    mounted(){
      this.dialogFormVisible1=this.$props.chufa
    },
    methods:{
        sure(){
              this.$axios({
                        method: "post",
                        url: "/s/backend/api/changepass",
                        params: {
                        accessToken : localStorage.getItem('token')
                        },
                        data:{
                    "jobnumber":  localStorage.getItem('jobnumber'),
                    "oldpassword": this.form.oldpassword,
                    "newpassword": this.form.newpassword
                    },
                        }) .then(res=>{
                            if(res.data.code==0){
                                  this.dialogFormVisible = false
                              this.$message({
                                message:'修改成功',
                                type: 'success'
                                    });
                            }
                          
                        })
        .catch(function(){
           
        })  
        }
    }
  };
</script>

<style scoped>

</style>