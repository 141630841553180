<template>
  
  <div>
       <div class="header">
             <ul class="left_menu">
                <div class="item"> 
                    <li class=" a"><router-link to='/index/js'>管理员</router-link></li>
                     <li class="active a">职位管理</li>
                       <li class=" a"><router-link to='/index/cwzj'> 财务总监</router-link></li>
                       
                </div>
                 <div  class="item">  
                    <li class="a"><router-link to="/index/xiaoxi">  <i class=" el-icon-message-solid" style="color:#0034FF;width:25px;height:25px;border-radius:100%;background: #f5f5f5;text-align:center;line-height:25px;margin-right:5px"></i>消息通知</router-link> </li>
                     <li style="cursor:pointer;" @click="dialogFormVisible = true">总台管理<i class="el-icon-caret-bottom"></i>  </li>
                </div>    

                                        <el-dialog  class="kk" title="个人信息" style="text-align:center" :visible.sync="dialogFormVisible">
                        <el-form :model="info">
                            <el-form-item label="昵称" :label-width="formLabelWidth">
                            <el-input v-model="info.name" autocomplete="off">  <el-button slot="append" @click="a11()">修改</el-button></el-input>
                         
                            </el-form-item>
                            <el-form-item label="登录账号" :label-width="formLabelWidth">
                             <el-input v-model="info.phone" autocomplete="off"  :disabled="true"></el-input>
                            </el-form-item>
                             <el-form-item label="登录密码" :label-width="formLabelWidth">
                             <el-input v-model="password3" placeholder="输入旧密码" autocomplete="off">  <el-button slot="append"  @click="a22()">修改</el-button></el-input>
                            </el-form-item>
                            <el-form-item label="新密码" v-show="password2" :label-width="formLabelWidth">
                             <el-input v-model="password" autocomplete="off">  </el-input>
                            </el-form-item>
                        </el-form>
                        <div slot="footer" class="dialog-footer">
                            <el-button type="primary" @click="tuichu" style="width:100%">退出登录</el-button>
                        </div>
                        </el-dialog>  
             </ul>
        </div>
        <p style="font-size: 22px;

font-weight: 600;
color: #000000;
line-height: 30px;margin:20px 40px">角色管理 <span style="font-size: 14px;
font-family: PingFangSC-Regular, PingFang SC;
font-weight: 400;
color: #000000;
line-height: 20px;">服务于业务发展的各个角色</span></p>

       <div class="change" style="padding:15px 40px;background:#f5f5f5">
        <el-tabs v-model="activeName" type="card" @tab-click="handleClick">
            <el-tab-pane label="市场总监" name="first">
            <div style="display:flex" class="list"> <p @click="show1" :class="{'actives':key1==0}">列表</p>
            <p @click="show2" :class="{'actives':key1==1}">业务统计</p></div>   
   
      <div class="content" v-show="key1==0" style="padding:0;background:#f5f5f5">
             
                        <div style="padding:10px;background:#fff">
                                <div style="display:flex;    justify-content: space-between;align-items: center;
                                margin-bottom: 15px;">
                                <p style="font-size: 16px;
                font-family: PingFangSC-Medium, PingFang SC;
                font-weight: 500;
                color: #000000;
                line-height: 22px;">市场总监</p>
                            <el-button
                        size="mini"
                        
                        @click="dialogFormVisible1 = true">创建账号</el-button>

                        

                                <el-dialog title="创建市场总监" :visible.sync="dialogFormVisible1">
                                <el-form :model="form1">
                                    <el-form-item label="姓名" :label-width="formLabelWidth">
                                    <el-input v-model="form1.name" autocomplete="off"></el-input>
                                    </el-form-item>
                                    <el-form-item label="电话" :label-width="formLabelWidth">
                                    <el-input v-model="form1.phone" autocomplete="off"></el-input>
                                    </el-form-item>
                                    <el-form-item label="区域选择" :label-width="formLabelWidth">
                                    <el-cascader  :props="props" :options="options"   @change="changeProvinceCity">
                                        <template slot-scope="{ node, data }">
                                            <span>{{ data.label }}</span>
                                        
                                            <span v-if="!node.isLeaf"> ({{ data.children.length }}) </span>
                                        </template>
                                        </el-cascader>
                                    </el-form-item>
                                </el-form>
                                <div slot="footer" class="dialog-footer">
                            
                                    <el-button type="primary" @click="tianjia1" style="width:100%">添加</el-button>
                                </div>
                                </el-dialog>
                </div>
                                <el-table
                    :border="true"
                    :data="tableData"
                :header-cell-style="{background:'#FAFAFA'}"
                    style="width: 100%"
                
                    >
                
                
                    <el-table-column
                    :show-overflow-tooltip="true" 
                
                    prop="id"
                    label="ID"
                    >
                    </el-table-column>
                    <el-table-column
                    label="姓名"
                    prop="name" 
                    >
                    
                    </el-table-column>

                    <el-table-column
                    label="手机号"
                    prop="phone" 
                    
                    >

                    
                    
                    </el-table-column>
                    <el-table-column
                        prop="createtime" 
                    label="最近登录时间" 
                        :show-overflow-tooltip="true" 
                    >
                    </el-table-column>
                    <el-table-column     
                    label="用户状态" 
                        :show-overflow-tooltip="true" 
                    >
                    <template slot-scope="scope" >
                    <p :class="{red:scope.row.isactive!==0}"> {{scope.row.isactive | capitalize}}</p>
                    </template>
                    </el-table-column>


                    

                <el-table-column
                    
                    label="操作"
                >
                    <template slot-scope="scope">

                        
                        <el-button
                        size="mini"
                            type="danger"
                            v-if='scope.row.isactive==0'
                        @click="handleEdit(scope.$index, scope.row)">禁用账号</el-button>
                            <el-button
                                v-if='scope.row.isactive!==0'
                        size="mini"
                            type='info'
                        @click="handleEdit1(scope.$index, scope.row)">恢复账号</el-button>
                        
                    </template>
                    </el-table-column>
                </el-table>
           </div>
                      



            </div>

               <div class="content"  v-show="key1==1" style="padding:0;background:#f5f5f5">
             
                        <div style="padding:10px;background:#fff">
                                <div style="display:flex;    justify-content: space-between;align-items: center;
                                margin-bottom: 15px;">
                                <p style="font-size: 16px;
                font-family: PingFangSC-Medium, PingFang SC;
                font-weight: 500;
                color: #000000;
                line-height: 22px;">市场总监 | 统计 </p>
                          

                        

                                <el-dialog title="创建管理员" :visible.sync="dialogFormVisible3">
                                <el-form :model="form1">
                                    <el-form-item label="姓名" :label-width="formLabelWidth">
                                    <el-input v-model="form1.name" autocomplete="off"></el-input>
                                    </el-form-item>
                                    <el-form-item label="电话" :label-width="formLabelWidth">
                                    <el-input v-model="form1.phone" autocomplete="off"></el-input>
                                    </el-form-item>
                                    <el-form-item label="管理员类型" :label-width="formLabelWidth">
                                    <el-select v-model="form1.region" placeholder="请选择活动区域">
                                        <el-option label="普通管理员" value="9"></el-option>
                                        <el-option label="管理员" value="10"></el-option>
                                    </el-select>
                                    </el-form-item>
                                </el-form>
                                <div slot="footer" class="dialog-footer">
                            
                                    <el-button type="primary" @click="tianjia" style="width:100%">添加</el-button>
                                </div>
                                </el-dialog>
                </div>


                    <el-table
      :border="true"
    :data="tb1"
   :header-cell-style="{background:'#FAFAFA'}"
    style="width: 100%"

    >
   
   
     <el-table-column
     :show-overflow-tooltip="true" 
   
      prop="id"
      label="ID"
     >
    </el-table-column>
    <el-table-column
      label="姓名"
      prop="name" 
     >
    
    </el-table-column>

       <el-table-column
      label="手机号"
    prop="phone" 
      
    >
    
       </el-table-column>
   
         <el-table-column
      label="业务经理数"
    prop="tlvsl" 
      
    >
    
       </el-table-column>
         <el-table-column
      label="琴行数量"
     prop="jszysl" 
      
    >
    
       </el-table-column>
                <el-table-column
      label="会员数量"
    prop="hysl" 
      
    >
    
       </el-table-column>
                <el-table-column
      label="订单数量"
    prop="ordersl" 
      
    >
    
       </el-table-column>
  </el-table>
                         
           </div>
                      



            </div>

            </el-tab-pane>
            <el-tab-pane label="市场 | 业务经理" name="second">
                    <div style="display:flex" class="list"> <p @click="show1" :class="{'actives':key1==0}">列表</p>
                    <p @click="show22" :class="{'actives':key1==1}">业务统计</p></div>   
   
      <div class="content" v-show="key1==0" style="padding:0;background:#f5f5f5">
             
                        <div style="padding:10px;background:#fff">
                                <div style="display:flex;    justify-content: space-between;align-items: center;
                                margin-bottom: 15px;">
                                <p style="font-size: 16px;
                font-family: PingFangSC-Medium, PingFang SC;
                font-weight: 500;
                color: #000000;
                line-height: 22px;">市场业务经理</p>
                            <!-- <el-button
                        size="mini"
                        
                        @click="dialogFormVisible11 = true">创建账号</el-button> -->

                        

                                 <el-dialog title="创建市场业务经理" :visible.sync="dialogFormVisible11">
                                <el-form :model="form2">
                                    <el-form-item label="姓名" :label-width="formLabelWidth">
                                    <el-input v-model="form2.name" autocomplete="off"></el-input>
                                    </el-form-item>
                                    <el-form-item label="电话" :label-width="formLabelWidth">
                                    <el-input v-model="form1.phone" autocomplete="off"></el-input>
                                    </el-form-item>
                                    <el-form-item label="区域选择" :label-width="formLabelWidth">
                                    <el-cascader  :props="props" :options="options"   @change="changeProvinceCity">
                                        <template slot-scope="{ node, data }">
                                            <span>{{ data.label }}</span>
                                        
                                            <span v-if="!node.isLeaf"> ({{ data.children.length }}) </span>
                                        </template>
                                        </el-cascader>
                                    </el-form-item>
                                </el-form>
                                <div slot="footer" class="dialog-footer">
                            
                                    <el-button type="primary" @click="tianjia2" style="width:100%">添加</el-button>
                                </div>
                                </el-dialog>
                </div>
                                <el-table
                    :border="true"
                    :data="tableData"
                :header-cell-style="{background:'#FAFAFA'}"
                    style="width: 100%"
                
                    >
                
                
                    <el-table-column
                    :show-overflow-tooltip="true" 
                
                    prop="id"
                    label="ID"
                    >
                    </el-table-column>
                    <el-table-column
                    label="姓名"
                    prop="name" 
                    >
                    
                    </el-table-column>

                    <el-table-column
                    label="手机号"
                    prop="phone" 
                    
                    >
                    
                    </el-table-column>
                    <el-table-column
                        prop="createtime" 
                    label="最近登录时间" 
                        :show-overflow-tooltip="true" 
                    >
                    </el-table-column>
                    <el-table-column     
                    label="用户状态" 
                        :show-overflow-tooltip="true" 
                    >
                    <template slot-scope="scope" >
                    <p :class="{red:scope.row.isactive!==0}"> {{scope.row.isactive | capitalize}}</p>
                    </template>
                    </el-table-column>


                    

                <el-table-column
                    
                    label="操作"
                >
                    <template slot-scope="scope">

                        
                        <el-button
                        size="mini"
                            type="danger"
                            v-if='scope.row.isactive==0'
                        @click="handleEdit2(scope.$index, scope.row)">禁用账号</el-button>
                            <el-button
                                v-if='scope.row.isactive!==0'
                        size="mini"
                            type='info'
                        @click="handleEdit3(scope.$index, scope.row)">恢复账号</el-button>
                        
                    </template>
                    </el-table-column>
                </el-table>
           </div>
                      



            </div>

               <div class="content"  v-show="key1==1" style="padding:0;background:#f5f5f5">
             
                        <div style="padding:10px;background:#fff">
                                <div style="display:flex;    justify-content: space-between;align-items: center;
                                margin-bottom: 15px;">
                                <p style="font-size: 16px;
                font-family: PingFangSC-Medium, PingFang SC;
                font-weight: 500;
                color: #000000;
                line-height: 22px;">市场总监 · 业务经理 | 业务统计 </p>
                         

                        

                                <el-dialog title="创建管理员" :visible.sync="dialogFormVisible3">
                                <el-form :model="form1">
                                    <el-form-item label="姓名" :label-width="formLabelWidth">
                                    <el-input v-model="form1.name" autocomplete="off"></el-input>
                                    </el-form-item>
                                    <el-form-item label="电话" :label-width="formLabelWidth">
                                    <el-input v-model="form1.phone" autocomplete="off"></el-input>
                                    </el-form-item>
                                    <el-form-item label="管理员类型" :label-width="formLabelWidth">
                                    <el-select v-model="form1.region" placeholder="请选择活动区域">
                                        <el-option label="普通管理员" value="9"></el-option>
                                        <el-option label="管理员" value="10"></el-option>
                                    </el-select>
                                    </el-form-item>
                                </el-form>
                                <div slot="footer" class="dialog-footer">
                            
                                    <el-button type="primary" @click="tianjia" style="width:100%">添加</el-button>
                                </div>
                                </el-dialog>
                </div>


                    <el-table
      :border="true"
    :data="tb1"
   :header-cell-style="{background:'#FAFAFA'}"
    style="width: 100%"

    >
   
   
     <el-table-column
     :show-overflow-tooltip="true" 
   
      prop="id"
      label="ID"
     >
    </el-table-column>
    <el-table-column
      label="姓名"
      prop="name" 
     >
    
    </el-table-column>

       <el-table-column
      label="手机号"
    prop="phone" 
      
    >
    
       </el-table-column>
       <el-table-column
      label="代理商数量"
    prop="dlssl" 
      
    >
    
       </el-table-column>
     
         <el-table-column
      label="琴行数量"
    prop="qhsl" 
      
    >
    
       </el-table-column>
                <el-table-column
      label="会员数量"
    prop="hysl" 
      
    >
    
       </el-table-column>
                <el-table-column
      label="订单数量"
    prop="ordersl" 
      
    >
    
       </el-table-column>
  </el-table>
                         
           </div>
                      



            </div>
             
          </el-tab-pane>

           <el-tab-pane label="技术总监" name="third">
                    <div style="display:flex" class="list"> <p @click="show1" :class="{'actives':key1==0}">列表</p>
                    <p @click="show33" :class="{'actives':key1==1}">业务统计</p></div>   
   
      <div class="content" v-show="key1==0" style="padding:0;background:#f5f5f5">
             
                        <div style="padding:10px;background:#fff">
                                <div style="display:flex;    justify-content: space-between;align-items: center;
                                margin-bottom: 15px;">
                                <p style="font-size: 16px;
                font-family: PingFangSC-Medium, PingFang SC;
                font-weight: 500;
                color: #000000;
                line-height: 22px;">技术总监</p>
                            <el-button
                        size="mini"
                        
                        @click="dialogFormVisible33 = true">创建账号</el-button>
                                <el-dialog title="创建技术总监" :visible.sync="dialogFormVisible33">
                                <el-form :model="form3">
                                    <el-form-item label="姓名" :label-width="formLabelWidth">
                                    <el-input v-model="form3.name" autocomplete="off"></el-input>
                                    </el-form-item>
                                    <el-form-item label="电话" :label-width="formLabelWidth">
                                    <el-input v-model="form3.phone" autocomplete="off"></el-input>
                                    </el-form-item>
                                    <el-form-item label="区域选择" :label-width="formLabelWidth">
                                    <el-cascader  :props="props" :options="options"   @change="changeProvinceCity">
                                        <template slot-scope="{ node, data }">
                                            <span>{{ data.label }}</span>
                                        
                                            <span v-if="!node.isLeaf"> ({{ data.children.length }}) </span>
                                        </template>
                                        </el-cascader>
                                    </el-form-item>
                                </el-form>
                                <div slot="footer" class="dialog-footer">
                            
                                    <el-button type="primary" @click="tianjia3" style="width:100%">添加</el-button>
                                </div>
                                </el-dialog>
                </div>
                                <el-table
                    :border="true"
                    :data="tableData"
                :header-cell-style="{background:'#FAFAFA'}"
                    style="width: 100%"
                
                    >
                
                
                    <el-table-column
                    :show-overflow-tooltip="true" 
                
                    prop="id"
                    label="ID"
                    >
                    </el-table-column>
                    <el-table-column
                    label="姓名"
                    prop="name" 
                    >
                    
                    </el-table-column>

                    <el-table-column
                    label="手机号"
                    prop="phone" 
                    
                    >
                    
                    </el-table-column>
                    <el-table-column
                        prop="createtime" 
                    label="最近登录时间" 
                        :show-overflow-tooltip="true" 
                    >
                    </el-table-column>
                    <el-table-column     
                    label="用户状态" 
                        :show-overflow-tooltip="true" 
                    >
                    <template slot-scope="scope" >
                    <p :class="{red:scope.row.isactive!==0}"> {{scope.row.isactive | capitalize}}</p>
                    </template>
                    </el-table-column>


                    

                <el-table-column
                    
                    label="操作"
                >
                    <template slot-scope="scope">

                        
                        <el-button
                        size="mini"
                            type="danger"
                            v-if='scope.row.isactive==0'
                        @click="handleEdit4(scope.$index, scope.row)">禁用账号</el-button>
                            <el-button
                                v-if='scope.row.isactive!==0'
                        size="mini"
                            type='info'
                        @click="handleEdit5(scope.$index, scope.row)">恢复账号</el-button>
                        
                    </template>
                    </el-table-column>
                </el-table>
           </div>
                      



            </div>

               <div class="content"  v-show="key1==1" style="padding:0;background:#f5f5f5">
             
                        <div style="padding:10px;background:#fff">
                                <div style="display:flex;    justify-content: space-between;align-items: center;
                                margin-bottom: 15px;">
                                <p style="font-size: 16px;
                font-family: PingFangSC-Medium, PingFang SC;
                font-weight: 500;
                color: #000000;
                line-height: 22px;">技术总监 | 业务统计 </p>
                         

                        

                                <el-dialog title="创建管理员" :visible.sync="dialogFormVisible3">
                                <el-form :model="form1">
                                    <el-form-item label="姓名" :label-width="formLabelWidth">
                                    <el-input v-model="form1.name" autocomplete="off"></el-input>
                                    </el-form-item>
                                    <el-form-item label="电话" :label-width="formLabelWidth">
                                    <el-input v-model="form1.phone" autocomplete="off"></el-input>
                                    </el-form-item>
                                    <el-form-item label="管理员类型" :label-width="formLabelWidth">
                                    <el-select v-model="form1.region" placeholder="请选择活动区域">
                                        <el-option label="普通管理员" value="9"></el-option>
                                        <el-option label="管理员" value="10"></el-option>
                                    </el-select>
                                    </el-form-item>
                                </el-form>
                                <div slot="footer" class="dialog-footer">
                            
                                    <el-button type="primary" @click="tianjia" style="width:100%">添加</el-button>
                                </div>
                                </el-dialog>
                </div>


                    <el-table
      :border="true"
    :data="tb1"
   :header-cell-style="{background:'#FAFAFA'}"
    style="width: 100%"

    >
   
   
     <el-table-column
     :show-overflow-tooltip="true" 
   
      prop="id"
      label="ID"
     >
    </el-table-column>
    <el-table-column
      label="姓名"
      prop="name" 
     >
    
    </el-table-column>

       <el-table-column
      label="手机号"
    prop="phone" 
      
    >
    
       </el-table-column>
       <el-table-column
      label="代理商数量"
    prop="dlssl" 
      
    >
    
       </el-table-column>
         <el-table-column
      label="业务经理数"
    prop="ywjlsl" 
      
    >
    
       </el-table-column>
         <el-table-column
      label="琴行数量"
    prop="qhsl" 
      
    >
    
       </el-table-column>
                <el-table-column
      label="会员数量"
    prop="hysl" 
      
    >
    
       </el-table-column>
                <el-table-column
      label="订单数量"
    prop="ordersl" 
      
    >
    
       </el-table-column>
  </el-table>
                         
           </div>
                      



            </div>
             
          </el-tab-pane>

             <el-tab-pane label="技术专员" name="fourth">
                    <div style="display:flex" class="list"> <p @click="show1" :class="{'actives':key1==0}">列表</p>
                    <p @click="show44" :class="{'actives':key1==1}">业务统计</p></div>   
   
      <div class="content" v-show="key1==0" style="padding:0;background:#f5f5f5">
             
                        <div style="padding:10px;background:#fff">
                                <div style="display:flex;    justify-content: space-between;align-items: center;
                                margin-bottom: 15px;">
                                <p style="font-size: 16px;
                font-family: PingFangSC-Medium, PingFang SC;
                font-weight: 500;
                color: #000000;
                line-height: 22px;">技术专员</p>
                            <!-- <el-button
                        size="mini"
                        
                        @click="dialogFormVisible2 = true">创建账号</el-button> -->

                        

                                <el-dialog title="创建管理员" :visible.sync="dialogFormVisible2">
                                <el-form :model="form1">
                                    <el-form-item label="姓名" :label-width="formLabelWidth">
                                    <el-input v-model="form1.name" autocomplete="off"></el-input>
                                    </el-form-item>
                                    <el-form-item label="电话" :label-width="formLabelWidth">
                                    <el-input v-model="form1.phone" autocomplete="off"></el-input>
                                    </el-form-item>
                                    <el-form-item label="管理员类型" :label-width="formLabelWidth">
                                    <el-select v-model="form1.region" placeholder="请选择活动区域">
                                        <el-option label="普通管理员" value="9"></el-option>
                                        <el-option label="管理员" value="10"></el-option>
                                    </el-select>
                                    </el-form-item>
                                </el-form>
                                <div slot="footer" class="dialog-footer">
                            
                                    <el-button type="primary" @click="tianjia" style="width:100%">添加</el-button>
                                </div>
                                </el-dialog>
                </div>
                                <el-table
                    :border="true"
                    :data="tableData"
                :header-cell-style="{background:'#FAFAFA'}"
                    style="width: 100%"
                
                    >
                
                
                    <el-table-column
                    :show-overflow-tooltip="true" 
                
                    prop="id"
                    label="ID"
                    >
                    </el-table-column>
                    <el-table-column
                    label="姓名"
                    prop="name" 
                    >
                    
                    </el-table-column>

                    <el-table-column
                    label="手机号"
                    prop="phone" 
                    
                    >
                    
                    </el-table-column>
                    <el-table-column
                        prop="createtime" 
                    label="最近登录时间" 
                        :show-overflow-tooltip="true" 
                    >
                    </el-table-column>
                    <el-table-column     
                    label="用户状态" 
                        :show-overflow-tooltip="true" 
                    >
                    <template slot-scope="scope" >
                    <p :class="{red:scope.row.isactive!==0}"> {{scope.row.isactive | capitalize}}</p>
                    </template>
                    </el-table-column>


                    

                <el-table-column
                    
                    label="操作"
                >
                    <template slot-scope="scope">

                        
                        <el-button
                        size="mini"
                            type="danger"
                            v-if='scope.row.isactive==0'
                        @click="handleEdit6(scope.$index, scope.row)">禁用账号</el-button>
                            <el-button
                                v-if='scope.row.isactive!==0'
                        size="mini"
                            type='info'
                        @click="handleEdit7(scope.$index, scope.row)">恢复账号</el-button>
                        
                    </template>
                    </el-table-column>
                </el-table>
           </div>
                      



            </div>

               <div class="content"  v-show="key1==1" style="padding:0;background:#f5f5f5">
             
                        <div style="padding:10px;background:#fff">
                                <div style="display:flex;    justify-content: space-between;align-items: center;
                                margin-bottom: 15px;">
                                <p style="font-size: 16px;
                font-family: PingFangSC-Medium, PingFang SC;
                font-weight: 500;
                color: #000000;
                line-height: 22px;">技术专员 | 业务统计 </p>
                         

                        

                                <el-dialog title="创建管理员" :visible.sync="dialogFormVisible3">
                                <el-form :model="form1">
                                    <el-form-item label="姓名" :label-width="formLabelWidth">
                                    <el-input v-model="form1.name" autocomplete="off"></el-input>
                                    </el-form-item>
                                    <el-form-item label="电话" :label-width="formLabelWidth">
                                    <el-input v-model="form1.phone" autocomplete="off"></el-input>
                                    </el-form-item>
                                    <el-form-item label="管理员类型" :label-width="formLabelWidth">
                                    <el-select v-model="form1.region" placeholder="请选择活动区域">
                                        <el-option label="普通管理员" value="9"></el-option>
                                        <el-option label="管理员" value="10"></el-option>
                                    </el-select>
                                    </el-form-item>
                                </el-form>
                                <div slot="footer" class="dialog-footer">
                            
                                    <el-button type="primary" @click="tianjia" style="width:100%">添加</el-button>
                                </div>
                                </el-dialog>
                </div>


                    <el-table
      :border="true"
    :data="tb1"
   :header-cell-style="{background:'#FAFAFA'}"
    style="width: 100%"

    >
   
   
     <el-table-column
     :show-overflow-tooltip="true" 
   
      prop="id"
      label="ID"
     >
    </el-table-column>
    <el-table-column
      label="姓名"
      prop="name" 
     >
    
    </el-table-column>

       <el-table-column
      label="手机号"
    prop="phone" 
      
    >
    
       </el-table-column>
       <el-table-column
      label="代理商数量"
    prop="dlssl" 
      
    >
    
       </el-table-column>
         <el-table-column
      label="业务经理数"
    prop="ywjlsl" 
      
    >
    
       </el-table-column>
         <el-table-column
      label="琴行数量"
    prop="qhsl" 
      
    >
    
       </el-table-column>
                <el-table-column
      label="会员数量"
    prop="hysl" 
      
    >
    
       </el-table-column>
                <el-table-column
      label="订单数量"
    prop="ordersl" 
      
    >
    
       </el-table-column>
  </el-table>
                         
           </div>
                      



            </div>
             
          </el-tab-pane>
            
              <el-tab-pane label="代理商" name="five">
                    <div style="display:flex" class="list"> <p @click="show1" :class="{'actives':key1==0}">列表</p>
                    <p @click="show55" :class="{'actives':key1==1}">业务统计</p></div>   
   
      <div class="content" v-show="key1==0" style="padding:0;background:#f5f5f5">
             
                        <div style="padding:10px;background:#fff">
                                <div style="display:flex;    justify-content: space-between;align-items: center;
                                margin-bottom: 15px;">
                                <p style="font-size: 16px;
                font-family: PingFangSC-Medium, PingFang SC;
                font-weight: 500;
                color: #000000;
                line-height: 22px;">代理商</p>
                            <!-- <el-button
                        size="mini"
                        
                        @click="dialogFormVisible2 = true">创建账号</el-button> -->

                        

                                <el-dialog title="创建管理员" :visible.sync="dialogFormVisible2">
                                <el-form :model="form1">
                                    <el-form-item label="姓名" :label-width="formLabelWidth">
                                    <el-input v-model="form1.name" autocomplete="off"></el-input>
                                    </el-form-item>
                                    <el-form-item label="电话" :label-width="formLabelWidth">
                                    <el-input v-model="form1.phone" autocomplete="off"></el-input>
                                    </el-form-item>
                                    <el-form-item label="管理员类型" :label-width="formLabelWidth">
                                    <el-select v-model="form1.region" placeholder="请选择活动区域">
                                        <el-option label="普通管理员" value="9"></el-option>
                                        <el-option label="管理员" value="10"></el-option>
                                    </el-select>
                                    </el-form-item>
                                </el-form>
                                <div slot="footer" class="dialog-footer">
                            
                                    <el-button type="primary" @click="tianjia" style="width:100%">添加</el-button>
                                </div>
                                </el-dialog>
                </div>
                                <el-table
                    :border="true"
                    :data="tableData"
                :header-cell-style="{background:'#FAFAFA'}"
                    style="width: 100%"
                
                    >
                
                
                    <el-table-column
                    :show-overflow-tooltip="true" 
                
                    prop="id"
                    label="ID"
                    >
                    </el-table-column>
                    <el-table-column
                    label="姓名"
                    prop="name" 
                    >
                    
                    </el-table-column>

                    <el-table-column
                    label="手机号"
                    prop="phone" 
                    
                    >
                    
                    </el-table-column>
                    <el-table-column
                        prop="createtime" 
                    label="最近登录时间" 
                        :show-overflow-tooltip="true" 
                    >
                    </el-table-column>
                    <el-table-column     
                    label="用户状态" 
                        :show-overflow-tooltip="true" 
                    >
                    <template slot-scope="scope" >
                    <p :class="{red:scope.row.isactive!==0}"> {{scope.row.isactive | capitalize}}</p>
                    </template>
                    </el-table-column>


                    

                <el-table-column
                    
                    label="操作"
                >
                    <template slot-scope="scope">

                        
                        <el-button
                        size="mini"
                            type="danger"
                            v-if='scope.row.isactive==0'
                        @click="handleEdit8(scope.$index, scope.row)">禁用账号</el-button>
                            <el-button
                                v-if='scope.row.isactive!==0'
                        size="mini"
                            type='info'
                        @click="handleEdit9(scope.$index, scope.row)">恢复账号</el-button>
                        
                    </template>
                    </el-table-column>
                </el-table>
           </div>
                      



            </div>

               <div class="content"  v-show="key1==1" style="padding:0;background:#f5f5f5">
             
                        <div style="padding:10px;background:#fff">
                                <div style="display:flex;    justify-content: space-between;align-items: center;
                                margin-bottom: 15px;">
                                <p style="font-size: 16px;
                font-family: PingFangSC-Medium, PingFang SC;
                font-weight: 500;
                color: #000000;
                line-height: 22px;">代理商 | 业务统计 </p>
                         

                        

                                <el-dialog title="创建管理员" :visible.sync="dialogFormVisible3">
                                <el-form :model="form1">
                                    <el-form-item label="姓名" :label-width="formLabelWidth">
                                    <el-input v-model="form1.name" autocomplete="off"></el-input>
                                    </el-form-item>
                                    <el-form-item label="电话" :label-width="formLabelWidth">
                                    <el-input v-model="form1.phone" autocomplete="off"></el-input>
                                    </el-form-item>
                                    <el-form-item label="管理员类型" :label-width="formLabelWidth">
                                    <el-select v-model="form1.region" placeholder="请选择活动区域">
                                        <el-option label="普通管理员" value="9"></el-option>
                                        <el-option label="管理员" value="10"></el-option>
                                    </el-select>
                                    </el-form-item>
                                </el-form>
                                <div slot="footer" class="dialog-footer">
                            
                                    <el-button type="primary" @click="tianjia" style="width:100%">添加</el-button>
                                </div>
                                </el-dialog>
                </div>


                    <el-table
      :border="true"
    :data="tb1"
   :header-cell-style="{background:'#FAFAFA'}"
    style="width: 100%"

    >
   
   
     <el-table-column
     :show-overflow-tooltip="true" 
   
      prop="id"
      label="ID"
     >
    </el-table-column>
    <el-table-column
      label="姓名"
      prop="name" 
     >
    
    </el-table-column>

       <el-table-column
      label="手机号"
    prop="phone" 
      
    >
    
       </el-table-column>
       <el-table-column
      label="代理商数量"
    prop="dlssl" 
      
    >
    
       </el-table-column>
         <el-table-column
      label="业务经理数"
    prop="ywjlsl" 
      
    >
    
       </el-table-column>
         <el-table-column
      label="琴行数量"
    prop="qhsl" 
      
    >
    
       </el-table-column>
                <el-table-column
      label="会员数量"
    prop="hysl" 
      
    >
    
       </el-table-column>
                <el-table-column
      label="订单数量"
    prop="ordersl" 
      
    >
    
       </el-table-column>
  </el-table>
                         
           </div>
                      



            </div>
             
          </el-tab-pane>

   <el-tab-pane label="代理商·业务经理" name="six">
                    <div style="display:flex" class="list"> <p @click="show1" :class="{'actives':key1==0}">列表</p>
                    <p @click="show22" :class="{'actives':key1==1}">业务统计</p></div>   
   
      <div class="content" v-show="key1==0" style="padding:0;background:#f5f5f5">
             
                        <div style="padding:10px;background:#fff">
                                <div style="display:flex;    justify-content: space-between;align-items: center;
                                margin-bottom: 15px;">
                                <p style="font-size: 16px;
                font-family: PingFangSC-Medium, PingFang SC;
                font-weight: 500;
                color: #000000;
                line-height: 22px;">代理商·业务经理</p>
                            <!-- <el-button
                        size="mini"
                        
                        @click="dialogFormVisible2 = true">创建账号</el-button> -->

                        

                                <el-dialog title="创建管理员" :visible.sync="dialogFormVisible2">
                                <el-form :model="form1">
                                    <el-form-item label="姓名" :label-width="formLabelWidth">
                                    <el-input v-model="form1.name" autocomplete="off"></el-input>
                                    </el-form-item>
                                    <el-form-item label="电话" :label-width="formLabelWidth">
                                    <el-input v-model="form1.phone" autocomplete="off"></el-input>
                                    </el-form-item>
                                    <el-form-item label="管理员类型" :label-width="formLabelWidth">
                                    <el-select v-model="form1.region" placeholder="请选择活动区域">
                                        <el-option label="普通管理员" value="9"></el-option>
                                        <el-option label="管理员" value="10"></el-option>
                                    </el-select>
                                    </el-form-item>
                                </el-form>
                                <div slot="footer" class="dialog-footer">
                            
                                    <el-button type="primary" @click="tianjia" style="width:100%">添加</el-button>
                                </div>
                                </el-dialog>
                </div>
                                <el-table
                    :border="true"
                    :data="tableData"
                :header-cell-style="{background:'#FAFAFA'}"
                    style="width: 100%"
                
                    >
                
                
                    <el-table-column
                    :show-overflow-tooltip="true" 
                
                    prop="id"
                    label="ID"
                    >
                    </el-table-column>
                    <el-table-column
                    label="姓名"
                    prop="name" 
                    >
                    
                    </el-table-column>

                    <el-table-column
                    label="手机号"
                    prop="phone" 
                    
                    >
                    
                    </el-table-column>
                    <el-table-column
                        prop="createtime" 
                    label="最近登录时间" 
                        :show-overflow-tooltip="true" 
                    >
                    </el-table-column>
                    <el-table-column     
                    label="用户状态" 
                        :show-overflow-tooltip="true" 
                    >
                    <template slot-scope="scope" >
                    <p :class="{red:scope.row.isactive!==0}"> {{scope.row.isactive | capitalize}}</p>
                    </template>
                    </el-table-column>


                    

                <el-table-column
                    
                    label="操作"
                >
                    <template slot-scope="scope">

                        
                        <el-button
                        size="mini"
                            type="danger"
                            v-if='scope.row.isactive==0'
                        @click="handleEdit10(scope.$index, scope.row)">禁用账号</el-button>
                            <el-button
                                v-if='scope.row.isactive!==0'
                        size="mini"
                            type='info'
                        @click="handleEdit11(scope.$index, scope.row)">恢复账号</el-button>
                        
                    </template>
                    </el-table-column>
                </el-table>
           </div>
                      



            </div>

               <div class="content"  v-show="key1==1" style="padding:0;background:#f5f5f5">
             
                        <div style="padding:10px;background:#fff">
                                <div style="display:flex;    justify-content: space-between;align-items: center;
                                margin-bottom: 15px;">
                                <p style="font-size: 16px;
                font-family: PingFangSC-Medium, PingFang SC;
                font-weight: 500;
                color: #000000;
                line-height: 22px;">代理商·业务经理 | 业务统计 </p>
                         

                        

                                <el-dialog title="创建管理员" :visible.sync="dialogFormVisible3">
                                <el-form :model="form1">
                                    <el-form-item label="姓名" :label-width="formLabelWidth">
                                    <el-input v-model="form1.name" autocomplete="off"></el-input>
                                    </el-form-item>
                                    <el-form-item label="电话" :label-width="formLabelWidth">
                                    <el-input v-model="form1.phone" autocomplete="off"></el-input>
                                    </el-form-item>
                                    <el-form-item label="管理员类型" :label-width="formLabelWidth">
                                    <el-select v-model="form1.region" placeholder="请选择活动区域">
                                        <el-option label="普通管理员" value="9"></el-option>
                                        <el-option label="管理员" value="10"></el-option>
                                    </el-select>
                                    </el-form-item>
                                </el-form>
                                <div slot="footer" class="dialog-footer">
                            
                                    <el-button type="primary" @click="tianjia" style="width:100%">添加</el-button>
                                </div>
                                </el-dialog>
                </div>


                    <el-table
      :border="true"
    :data="tb1"
   :header-cell-style="{background:'#FAFAFA'}"
    style="width: 100%"

    >
   
   
     <el-table-column
     :show-overflow-tooltip="true" 
   
      prop="id"
      label="ID"
     >
    </el-table-column>
    <el-table-column
      label="姓名"
      prop="name" 
     >
    
    </el-table-column>

       <el-table-column
      label="手机号"
    prop="phone" 
      
    >
    
       </el-table-column>
       <el-table-column
      label="代理商数量"
    prop="dlssl" 
      
    >
    
       </el-table-column>
         <el-table-column
      label="业务经理数"
    prop="ywjlsl" 
      
    >
    
       </el-table-column>
         <el-table-column
      label="琴行数量"
    prop="qhsl" 
      
    >
    
       </el-table-column>
                <el-table-column
      label="会员数量"
    prop="hysl" 
      
    >
    
       </el-table-column>
                <el-table-column
      label="订单数量"
    prop="ordersl" 
      
    >
    
       </el-table-column>
  </el-table>
                         
           </div>
                      



            </div>
             
          </el-tab-pane>
            
          
            <el-tab-pane label="调律师" name="seven"><div class="content" style="padding:20px 0;background:#f5f5f5">
             
           <div style="padding:10px;background:#fff">
                 <div style="display:flex;    justify-content: space-between;align-items: center;
                margin-bottom: 15px;">
                    <p style="font-size: 16px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #000000;
            line-height: 22px;">列表</p>
            <!-- <el-button
          size="mini"
           
           @click="dialogFormVisible3 = true">创建账号</el-button> -->

         

                <el-dialog title="创建管理员" :visible.sync="dialogFormVisible3">
                <el-form :model="form1">
                    <el-form-item label="姓名" :label-width="formLabelWidth">
                    <el-input v-model="form1.name" autocomplete="off"></el-input>
                    </el-form-item>
                     <el-form-item label="电话" :label-width="formLabelWidth">
                    <el-input v-model="form1.phone" autocomplete="off"></el-input>
                    </el-form-item>
                    <el-form-item label="管理员类型" :label-width="formLabelWidth">
                    <el-select v-model="form1.region" placeholder="请选择活动区域">
                        <el-option label="普通管理员" value="9"></el-option>
                        <el-option label="管理员" value="10"></el-option>
                    </el-select>
                    </el-form-item>
                </el-form>
                <div slot="footer" class="dialog-footer">
            
                    <el-button type="primary" @click="tianjia" style="width:100%">添加</el-button>
                </div>
                </el-dialog>
   </div>
                  <el-table
      :border="true"
    :data="tableData"
   :header-cell-style="{background:'#FAFAFA'}"
    style="width: 100%"

    >
   
   
     <el-table-column
     :show-overflow-tooltip="true" 
   
      prop="id"
      label="ID"
     >
    </el-table-column>
    <el-table-column
      label="姓名"
      prop="name" 
     >
    
    </el-table-column>

       <el-table-column
      label="手机号"
    prop="phone" 
      
    >
    
    </el-table-column>
      <el-table-column
        prop="createtime" 
      label="最近登录时间" 
           :show-overflow-tooltip="true" 
      >
    </el-table-column>
      <el-table-column     
      label="用户状态" 
           :show-overflow-tooltip="true" 
     >
      <template slot-scope="scope" >
       <p :class="{red:scope.row.isactive!==0}"> {{scope.row.isactive | capitalize}}</p>
      </template>
    </el-table-column>


    

   <el-table-column
     
      label="操作"
   >
     <template slot-scope="scope">

         
        <el-button
          size="mini"
            type="danger"
            v-if='scope.row.isactive==0'
           @click="handleEdit12(scope.$index, scope.row)">禁用账号</el-button>
             <el-button
                 v-if='scope.row.isactive!==0'
          size="mini"
            type='info'
           @click="handleEdit13(scope.$index, scope.row)">恢复账号</el-button>
        
     </template>
    </el-table-column>
  </el-table>
           </div>
                      



            </div></el-tab-pane>
            <el-tab-pane label="琴行" name="eight"><div class="content" style="padding:20px 0;background:#f5f5f5">
             
           <div style="padding:10px;background:#fff">
                 <div style="display:flex;    justify-content: space-between;align-items: center;
                margin-bottom: 15px;">
                    <p style="font-size: 16px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #000000;
            line-height: 22px;">列表</p>
            <!-- <el-button
          size="mini"
           
           @click="dialogFormVisible3 = true">创建账号</el-button> -->

         

                <el-dialog title="创建管理员" :visible.sync="dialogFormVisible3">
                <el-form :model="form1">
                    <el-form-item label="姓名" :label-width="formLabelWidth">
                    <el-input v-model="form1.name" autocomplete="off"></el-input>
                    </el-form-item>
                     <el-form-item label="电话" :label-width="formLabelWidth">
                    <el-input v-model="form1.phone" autocomplete="off"></el-input>
                    </el-form-item>
                    <el-form-item label="管理员类型" :label-width="formLabelWidth">
                    <el-select v-model="form1.region" placeholder="请选择活动区域">
                        <el-option label="普通管理员" value="9"></el-option>
                        <el-option label="管理员" value="10"></el-option>
                    </el-select>
                    </el-form-item>
                </el-form>
                <div slot="footer" class="dialog-footer">
            
                    <el-button type="primary" @click="tianjia" style="width:100%">添加</el-button>
                </div>
                </el-dialog>
   </div>
                  <el-table
      :border="true"
    :data="tableData"
   :header-cell-style="{background:'#FAFAFA'}"
    style="width: 100%"

    >
   
   
     <el-table-column
     :show-overflow-tooltip="true" 
   
      prop="id"
      label="ID"
     >
    </el-table-column>
    <el-table-column
      label="姓名"
      prop="name" 
     >
    
    </el-table-column>

       <el-table-column
      label="手机号"
    prop="phone" 
      
    >
    
    </el-table-column>
      <el-table-column
        prop="createtime" 
      label="最近登录时间" 
           :show-overflow-tooltip="true" 
      >
    </el-table-column>
      <el-table-column     
      label="用户状态" 
           :show-overflow-tooltip="true" 
     >
      <template slot-scope="scope" >
       <p :class="{red:scope.row.isactive!==0}"> {{scope.row.isactive | capitalize}}</p>
      </template>
    </el-table-column>


    

   <el-table-column
     
      label="操作"
   >
     <template slot-scope="scope">

         
        <el-button
          size="mini"
            type="danger"
            v-if='scope.row.isactive==0'
           @click="handleEdit14(scope.$index, scope.row)">禁用账号</el-button>
             <el-button
                 v-if='scope.row.isactive!==0'
          size="mini"
            type='info'
           @click="handleEdit15(scope.$index, scope.row)">恢复账号</el-button>
        
     </template>
    </el-table-column>
  </el-table>
           </div>
                      



            </div></el-tab-pane>
        </el-tabs>
    
       </div> 

            
  </div>
</template>

<script>
import pas from '@/components/password.vue'
export default {
        components:{
           pas 
        },
    filters: {
  capitalize: function (value) {
  if(value==0){
      return '正常'
    
  }else {
      return '禁用'
  }
  }
},
 data(){
        return{
               props: { multiple: true },
            key1:0,
              tabPosition: '列表',
             form1: {
          name: '',
          phone:'',
          region: '',
       
        },
            form2: {
          name: '',
          phone:'',
          region: '',
       
        },
         form3: {
          name: '',
          phone:'',
          region: '',
       
        },
dialogFormVisible3:false,
options:[],
            password:'',
            isactive:0,
            arr1:'',
           dialogFormVisible2:false,
            dialogFormVisible1:false,
            dialogFormVisible33:false,
            dialogFormVisible11:false,
                dialogFormVisible:false, form: {
          name: '',
          region: '',
          date1: '',
          date2: '',
          date3:[],
          delivery: false,
          type: [],
          resource: '',
          desc: ''
        },
        formLabelWidth: '120px',
        info:[],
        activeName: 'first',
        tableData: [],
        tb1:[],
        data1:[],
        data2:[],
        password:'',
        password2:false,
        password3:''
        }
        },
       methods:{
             tuichu(){
            this.dialogFormVisible = false;
            window.localStorage.removeItem('token')
                     this.$router.push({ name: 'login'})
        },
           changeProvinceCity(val) { 
               let arr = []
        
                console.log(val)
                for(var i =0;i<val.length;i++){
             arr = arr.concat(val[i][2])
           
                }
            
              
},
tianjia(){},
    tianjia1(){

            
                this.$axios({
                        method: "post",
                        url: "/s/backend/api/api/useradd",
                        params: {
                        accessToken : localStorage.getItem('token')
                        },
                        data:{ 
                        "arealist": this.arr,
                        "id": "string",
                        "idurl1": "string",
                        "idurl2": "string",
                        "key_1": "string",
                        "key_2": "string",
                        "key_3": "string",
                        "key_4": "string",
                        "name": this.form1.name,
                        "parent": 0,
                        "password": 123456,
                        "phone": this.form1.phone,
                        "roleid": 2,
                        "yyzcurl": "string",
                        "yyzz": "string",
                        "zsurl": "string"
                        }
                        }) .then(res=>{
                          
                            
                            if(res.data.code==0){
                                       this.$message({
                        message:res.data.msg,
                        type: 'success'
                            });
                                this.$axios.get("/s/backend/api/api/user_list", {
        params: { 'accessToken':localStorage.getItem('token'),
       page :1,
       limit :20,
       roleid :2  }
        })

     .then(res=>{
    
         this.tableData = res.data.data
 
        })
        .catch(function(){
           
        })

                            }

                        })
                        
        .catch(function(){
           
        })  
           },


  tianjia2(){

            
                this.$axios({
                        method: "post",
                        url: "/s/backend/api/api/useradd",
                        params: {
                        accessToken : localStorage.getItem('token')
                        },
                        data:{ 
                        "arealist": [
                            
                        ],
                        "id": "string",
                        "idurl1": "string",
                        "idurl2": "string",
                        "key_1": "string",
                        "key_2": "string",
                        "key_3": "string",
                        "key_4": "string",
                        "name": this.form1.name,
                        "parent": 0,
                        "password": 123456,
                        "phone": this.form1.phone,
                        "roleid": 11,
                        "yyzcurl": "string",
                        "yyzz": "string",
                        "zsurl": "string"
                        }
                        }) .then(res=>{
                          
                            
                            if(res.data.code==0){
                                       this.$message({
                        message:res.data.msg,
                        type: 'success'
                            });
                                this.$axios.get("/s/backend/api/api/user_list", {
        params: { 'accessToken':localStorage.getItem('token'),
       page :1,
       limit :20,
       roleid :11  }
        })

     .then(res=>{
    
         this.tableData = res.data.data
 
        })
        .catch(function(){
           
        })

                            }

                        })
                        
        .catch(function(){
           
        })  
           },

 tianjia3(){

            
                this.$axios({
                        method: "post",
                        url: "/s/backend/api/api/useradd",
                        params: {
                        accessToken : localStorage.getItem('token')
                        },
                        data:{ 
                        "arealist": [
                            
                        ],
                        "id": "string",
                        "idurl1": "string",
                        "idurl2": "string",
                        "key_1": "string",
                        "key_2": "string",
                        "key_3": "string",
                        "key_4": "string",
                        "name": this.form3.name,
                        "parent": 0,
                        "password": 123456,
                        "phone": this.form3.phone,
                        "roleid": 1,
                        "yyzcurl": "string",
                        "yyzz": "string",
                        "zsurl": "string"
                        }
                        }) .then(res=>{
                          
                            
                            if(res.data.code==0){
                                this.dialogFormVisible33 = false;
                                       this.$message({
                        message:res.data.msg,
                        type: 'success'
                            });
                                this.$axios.get("/s/backend/api/api/user_list", {
        params: { 'accessToken':localStorage.getItem('token'),
       page :1,
       limit :20,
       roleid :1  }
        }) 
     .then(res=>{
         this.tableData = res.data.data
        })
                          }

                        })
                        
        .catch(function(){
           
        })  
           },






show2(){this.key1=1

            this.$axios.get("/s/backend/api/api/sczj_bussiness_statistic", {
        params: { 'accessToken':localStorage.getItem('token') }
        })

     .then(res=>{
    
          this.tb1 = res.data.data
 
        })
        .catch(function(){
           
        })


},

show22(){this.key1=1

            this.$axios.get("/s/backend/api/api/sczj_bussiness_statistic", {
        params: { 'accessToken':localStorage.getItem('token') }
        })

     .then(res=>{
    
          this.tb1 = res.data.data
 
        })
        .catch(function(){
           
        })


},


show33(){this.key1=1

            this.$axios.get("/s​/backend​/api​/jszj_bussiness_statistic", {
        params: { 'accessToken':localStorage.getItem('token') }
        })

     .then(res=>{
    
          this.tb1 = res.data.data
 
        })
        .catch(function(){
           
        })


},



show44(){this.key1=1

            this.$axios.get("/s/backend/api/api/jszzy_bussiness_statistic", {
        params: { 'accessToken':localStorage.getItem('token') }
        })

     .then(res=>{
    
          this.tb1 = res.data.data
 
        })
        .catch(function(){
           
        })


},


show55(){this.key1=1

            this.$axios.get("/s​/backend​/api​/dls_bussiness_statistic", {
        params: { 'accessToken':localStorage.getItem('token') }
        })

     .then(res=>{
    
          this.tb1 = res.data.data
 
        })
        .catch(function(){
           
        })


},
show1(){this.key1=0},
           handleClick111(tab, event) {
          if(tab.name == 'first'){
              this.tab1()
              }
           },
handleClick(tab, event) {
          if(tab.name == 'first'){
              this.tab1()
          }else if( tab.name == 'second')
          {
             this.tab2() 
          }else if( tab.name == 'third')
          {
             this.tab3() 
          }else if( tab.name == 'fourth')
          {
             this.tab4() 
          }else if( tab.name == 'five')
          {
             this.tab5() 
          }else if( tab.name == 'six')
          {
             this.tab6() 
          }else if( tab.name == 'seven')
          {
             this.tab7() 
          }else if( tab.name == 'eight')
          {
             this.tab8() 
          }
      },
        //
         tab1(){
            this.$axios.get("/s/backend/api/api/user_list", {
        params: { 'accessToken':localStorage.getItem('token'),
       page :1,
       limit :20,
       roleid :2  }
        })

     .then(res=>{
    
          this.tableData = res.data.data
 
        })
        .catch(function(){
           
        })
        },
        tab2(){
            this.$axios.get("/s/backend/api/api/user_list", {
        params: { 'accessToken':localStorage.getItem('token'),
       page :1,
       limit :20,
       roleid :11  }
        })

     .then(res=>{
    
          this.tableData = res.data.data
 
        })
        .catch(function(){
           
        })
        },

   tab3(){
            this.$axios.get("/s/backend/api/api/user_list", {
        params: { 'accessToken':localStorage.getItem('token'),
       page :1,
       limit :20,
       roleid :1  }
        })

     .then(res=>{
    
         this.tableData = res.data.data
 
        })
        .catch(function(){
           
        })
        },

 tab4(){
            this.$axios.get("/s/backend/api/api/user_list", {
        params: { 'accessToken':localStorage.getItem('token'),
       page :1,
       limit :20,
       roleid :5  }
        })

     .then(res=>{
    
          this.tableData = res.data.data
 
        })
        .catch(function(){
           
        })
        },
 tab5(){
            this.$axios.get("/s/backend/api/api/user_list", {
        params: { 'accessToken':localStorage.getItem('token'),
       page :1,
       limit :20,
       roleid :4  }
        })

     .then(res=>{
    
        this.tableData = res.data.data
 
        })
        .catch(function(){
           
        })
        },
 tab6(){
            this.$axios.get("/s/backend/api/api/user_list", {
        params: { 'accessToken':localStorage.getItem('token'),
       page :1,
       limit :20,
       roleid :7  }
        })

     .then(res=>{
    
        this.tableData = res.data.data
 
        })
        .catch(function(){
           
        })
        },

         tab7(){
            this.$axios.get("/s/backend/api/api/user_list", {
        params: { 'accessToken':localStorage.getItem('token'),
       page :1,
       limit :20,
       roleid :6  }
        })

     .then(res=>{
    
         this.tableData = res.data.data
 
        })
        .catch(function(){
           
        })
        },

         tab8(){
            this.$axios.get("/s/backend/api/api/user_list", {
        params: { 'accessToken':localStorage.getItem('token'),
       page :1,
       limit :20,
       roleid :8 }
        })

     .then(res=>{
    
         this.tableData = res.data.data
 
        })
        .catch(function(){
           
        })
        },
      //
           tiania(){
               console.log(this.form1.region)
            
                this.$axios({
                        method: "post",
                        url: "/s/backend/api/api/useradd",
                        params: {
                        accessToken : localStorage.getItem('token')
                        },
                        data:{ 
                        "arealist": [
                            
                        ],
                        "id": "string",
                        "idurl1": "string",
                        "idurl2": "string",
                        "key_1": "string",
                        "key_2": "string",
                        "key_3": "string",
                        "key_4": "string",
                        "name": this.form1.name,
                        "parent": 0,
                        "password": 123456,
                        "phone": this.form1.phone,
                        "roleid": this.form1.region,
                        "yyzcurl": "string",
                        "yyzz": "string",
                        "zsurl": "string"
                        }
                        }) .then(res=>{
                                 this.$message({
                        message:res.data.msg,
                        type: 'success'
                            });
                                if(res.data.code==0){
                                       this.dialogFormVisible3=false
                                          this.$axios.get("/s/backend/api/api/user_list", {
        params: { 'accessToken':localStorage.getItem('token'),
       page :1,
       limit :20,
       roleid :10  }
        })

     .then(res=>{
    
         var data1 = res.data.data
   this.$axios.get("/s/backend/api/api/user_list", {
        params: { 'accessToken':localStorage.getItem('token'),
       page :1,
       limit :20,
       roleid :9  }
        })

     .then(res=>{
     
     this.tableData=data1.concat(this.data2)

        })
        .catch(function(){
           
        })
        })
        .catch(function(){
           
        })
    
                                }
                        })
        .catch(function(){
           
        })  
           },
          handleEdit(index,row){
              this.$axios({
                        method: "post",
                        url: "/s/backend/api/api/userban",
                        params: {
                        accessToken : localStorage.getItem('token')
                        },
                        data:{ "flag": 1,
                         "jobnumber":row.jobnumber},
                        }) .then(res=>{
                    this.$axios.get("/s/backend/api/api/user_list", {
                            params: { 'accessToken':localStorage.getItem('token'),
                        page :1,
                        limit :20,
                        roleid :2  }
                            })

                        .then(res=>{
                        
                            this.tableData = res.data.data
                    
                            })
                            .catch(function(){
                            
                            })
                            if(res.data.code==0){

                            }else{
                        this.$message({
                   message:res.data.msg,
                  type: 'success'
                     });
                            }
                                
                        })
        .catch(function(){
           
        })  
          },
             handleEdit1(index,row){
                  this.$axios({
                        method: "post",
                        url: "/s/backend/api/api/userban",
                        params: {
                        accessToken : localStorage.getItem('token')
                        },
                        data:{ "flag": 0,
                         "jobnumber":row.jobnumber},
                        }) .then(res=>{
                    this.$axios.get("/s/backend/api/api/user_list", {
                            params: { 'accessToken':localStorage.getItem('token'),
                        page :1,
                        limit :20,
                        roleid :2  }
                            })

                        .then(res=>{
                        
                            this.tableData = res.data.data
                    
                            })
                            .catch(function(){
                            
                            })
                            if(res.data.code==0){

                            }else{
                        this.$message({
                   message:res.data.msg,
                  type: 'success'
                     });
                            }
                                
                        })
        .catch(function(){
           
        })  
          },





            handleEdit2(index,row){
              this.$axios({
                        method: "post",
                        url: "/s/backend/api/api/userban",
                        params: {
                        accessToken : localStorage.getItem('token')
                        },
                        data:{ "flag": 1,
                         "jobnumber":row.jobnumber},
                        }) .then(res=>{
                    this.$axios.get("/s/backend/api/api/user_list", {
                            params: { 'accessToken':localStorage.getItem('token'),
                        page :1,
                        limit :20,
                        roleid :11  }
                            })

                        .then(res=>{
                        
                            this.tableData = res.data.data
                    
                            })
                            .catch(function(){
                            
                            })
                            if(res.data.code==0){

                            }else{
                        this.$message({
                   message:res.data.msg,
                  type: 'success'
                     });
                            }
                                
                        })
        .catch(function(){
           
        })  
          },
             handleEdit3(index,row){
                  this.$axios({
                        method: "post",
                        url: "/s/backend/api/api/userban",
                        params: {
                        accessToken : localStorage.getItem('token')
                        },
                        data:{ "flag": 0,
                         "jobnumber":row.jobnumber},
                        }) .then(res=>{
                    this.$axios.get("/s/backend/api/api/user_list", {
                            params: { 'accessToken':localStorage.getItem('token'),
                        page :1,
                        limit :20,
                        roleid :11  }
                            })

                        .then(res=>{
                        
                            this.tableData = res.data.data
                    
                            })
                            .catch(function(){
                            
                            })
                            if(res.data.code==0){

                            }else{
                        this.$message({
                   message:res.data.msg,
                  type: 'success'
                     });
                            }
                                
                        })
        .catch(function(){
           
        })  
          },








    handleEdit4(index,row){
              this.$axios({
                        method: "post",
                        url: "/s/backend/api/api/userban",
                        params: {
                        accessToken : localStorage.getItem('token')
                        },
                        data:{ "flag": 1,
                         "jobnumber":row.jobnumber},
                        }) .then(res=>{
                    this.$axios.get("/s/backend/api/api/user_list", {
                            params: { 'accessToken':localStorage.getItem('token'),
                        page :1,
                        limit :20,
                        roleid :1  }
                            })

                        .then(res=>{
                        
                            this.tableData = res.data.data
                    
                            })
                            .catch(function(){
                            
                            })
                            if(res.data.code==0){

                            }else{
                        this.$message({
                   message:res.data.msg,
                  type: 'success'
                     });
                            }
                                
                        })
        .catch(function(){
           
        })  
          },
             handleEdit5(index,row){
                  this.$axios({
                        method: "post",
                        url: "/s/backend/api/api/userban",
                        params: {
                        accessToken : localStorage.getItem('token')
                        },
                        data:{ "flag": 0,
                         "jobnumber":row.jobnumber},
                        }) .then(res=>{
                    this.$axios.get("/s/backend/api/api/user_list", {
                            params: { 'accessToken':localStorage.getItem('token'),
                        page :1,
                        limit :20,
                        roleid :1  }
                            })

                        .then(res=>{
                        
                            this.tableData = res.data.data
                    
                            })
                            .catch(function(){
                            
                            })
                            if(res.data.code==0){

                            }else{
                        this.$message({
                   message:res.data.msg,
                  type: 'success'
                     });
                            }
                                
                        })
        .catch(function(){
           
        })  
          },





    handleEdit6(index,row){
              this.$axios({
                        method: "post",
                        url: "/s/backend/api/api/userban",
                        params: {
                        accessToken : localStorage.getItem('token')
                        },
                        data:{ "flag": 1,
                         "jobnumber":row.jobnumber},
                        }) .then(res=>{
                    this.$axios.get("/s/backend/api/api/user_list", {
                            params: { 'accessToken':localStorage.getItem('token'),
                        page :1,
                        limit :20,
                        roleid :5 }
                            })

                        .then(res=>{
                        
                            this.tableData = res.data.data
                    
                            })
                            .catch(function(){
                            
                            })
                            if(res.data.code==0){

                            }else{
                        this.$message({
                   message:res.data.msg,
                  type: 'success'
                     });
                            }
                                
                        })
        .catch(function(){
           
        })  
          },
             handleEdit7(index,row){
                  this.$axios({
                        method: "post",
                        url: "/s/backend/api/api/userban",
                        params: {
                        accessToken : localStorage.getItem('token')
                        },
                        data:{ "flag": 0,
                         "jobnumber":row.jobnumber},
                        }) .then(res=>{
                    this.$axios.get("/s/backend/api/api/user_list", {
                            params: { 'accessToken':localStorage.getItem('token'),
                        page :1,
                        limit :20,
                        roleid :5  }
                            })

                        .then(res=>{
                        
                            this.tableData = res.data.data
                    
                            })
                            .catch(function(){
                            
                            })
                            if(res.data.code==0){

                            }else{
                        this.$message({
                   message:res.data.msg,
                  type: 'success'
                     });
                            }
                                
                        })
        .catch(function(){
           
        })  
          },


    handleEdit8(index,row){
              this.$axios({
                        method: "post",
                        url: "/s/backend/api/api/userban",
                        params: {
                        accessToken : localStorage.getItem('token')
                        },
                        data:{ "flag": 1,
                         "jobnumber":row.jobnumber},
                        }) .then(res=>{
                    this.$axios.get("/s/backend/api/api/user_list", {
                            params: { 'accessToken':localStorage.getItem('token'),
                        page :1,
                        limit :20,
                        roleid :4  }
                            })

                        .then(res=>{
                        
                            this.tableData = res.data.data
                    
                            })
                            .catch(function(){
                            
                            })
                            if(res.data.code==0){

                            }else{
                        this.$message({
                   message:res.data.msg,
                  type: 'success'
                     });
                            }
                                
                        })
        .catch(function(){
           
        })  
          },
             handleEdit9(index,row){
                  this.$axios({
                        method: "post",
                        url: "/s/backend/api/api/userban",
                        params: {
                        accessToken : localStorage.getItem('token')
                        },
                        data:{ "flag": 0,
                         "jobnumber":row.jobnumber},
                        }) .then(res=>{
                    this.$axios.get("/s/backend/api/api/user_list", {
                            params: { 'accessToken':localStorage.getItem('token'),
                        page :1,
                        limit :20,
                        roleid :4  }
                            })

                        .then(res=>{
                        
                            this.tableData = res.data.data
                    
                            })
                            .catch(function(){
                            
                            })
                            if(res.data.code==0){

                            }else{
                        this.$message({
                   message:res.data.msg,
                  type: 'success'
                     });
                            }
                                
                        })
        .catch(function(){
           
        })  
          },

    handleEdit10(index,row){
              this.$axios({
                        method: "post",
                        url: "/s/backend/api/api/userban",
                        params: {
                        accessToken : localStorage.getItem('token')
                        },
                        data:{ "flag": 1,
                         "jobnumber":row.jobnumber},
                        }) .then(res=>{
                    this.$axios.get("/s/backend/api/api/user_list", {
                            params: { 'accessToken':localStorage.getItem('token'),
                        page :1,
                        limit :20,
                        roleid :7 }
                            })

                        .then(res=>{
                        
                            this.tableData = res.data.data
                    
                            })
                            .catch(function(){
                            
                            })
                            if(res.data.code==0){

                            }else{
                        this.$message({
                   message:res.data.msg,
                  type: 'success'
                     });
                            }
                                
                        })
        .catch(function(){
           
        })  
          },
             handleEdit11(index,row){
                  this.$axios({
                        method: "post",
                        url: "/s/backend/api/api/userban",
                        params: {
                        accessToken : localStorage.getItem('token')
                        },
                        data:{ "flag": 0,
                         "jobnumber":row.jobnumber},
                        }) .then(res=>{
                    this.$axios.get("/s/backend/api/api/user_list", {
                            params: { 'accessToken':localStorage.getItem('token'),
                        page :1,
                        limit :20,
                        roleid :7 }
                            })

                        .then(res=>{
                        
                            this.tableData = res.data.data
                    
                            })
                            .catch(function(){
                            
                            })
                            if(res.data.code==0){

                            }else{
                        this.$message({
                   message:res.data.msg,
                  type: 'success'
                     });
                            }
                                
                        })
        .catch(function(){
           
        })  
          },


            handleEdit12(index,row){
              this.$axios({
                        method: "post",
                        url: "/s/backend/api/api/userban",
                        params: {
                        accessToken : localStorage.getItem('token')
                        },
                        data:{ "flag": 1,
                         "jobnumber":row.jobnumber},
                        }) .then(res=>{
                    this.$axios.get("/s/backend/api/api/user_list", {
                            params: { 'accessToken':localStorage.getItem('token'),
                        page :1,
                        limit :20,
                        roleid :6  }
                            })

                        .then(res=>{
                        
                            this.tableData = res.data.data
                    
                            })
                            .catch(function(){
                            
                            })
                            if(res.data.code==0){

                            }else{
                        this.$message({
                   message:res.data.msg,
                  type: 'success'
                     });
                            }
                                
                        })
        .catch(function(){
           
        })  
          },
             handleEdit13(index,row){
                  this.$axios({
                        method: "post",
                        url: "/s/backend/api/api/userban",
                        params: {
                        accessToken : localStorage.getItem('token')
                        },
                        data:{ "flag": 0,
                         "jobnumber":row.jobnumber},
                        }) .then(res=>{
                    this.$axios.get("/s/backend/api/api/user_list", {
                            params: { 'accessToken':localStorage.getItem('token'),
                        page :1,
                        limit :20,
                        roleid :6  }
                            })

                        .then(res=>{
                        
                            this.tableData = res.data.data
                    
                            })
                            .catch(function(){
                            
                            })
                            if(res.data.code==0){

                            }else{
                        this.$message({
                   message:res.data.msg,
                  type: 'success'
                     });
                            }
                                
                        })
        .catch(function(){
           
        })  
          },

    handleEdit14(index,row){
              this.$axios({
                        method: "post",
                        url: "/s/backend/api/api/userban",
                        params: {
                        accessToken : localStorage.getItem('token')
                        },
                        data:{ "flag": 1,
                         "jobnumber":row.jobnumber},
                        }) .then(res=>{
                    this.$axios.get("/s/backend/api/api/user_list", {
                            params: { 'accessToken':localStorage.getItem('token'),
                        page :1,
                        limit :20,
                        roleid :8  }
                            })

                        .then(res=>{
                        
                            this.tableData = res.data.data
                    
                            })
                            .catch(function(){
                            
                            })
                            if(res.data.code==0){

                            }else{
                        this.$message({
                   message:res.data.msg,
                  type: 'success'
                     });
                            }
                                
                        })
        .catch(function(){
           
        })  
          },
             handleEdit15(index,row){
                  this.$axios({
                        method: "post",
                        url: "/s/backend/api/api/userban",
                        params: {
                        accessToken : localStorage.getItem('token')
                        },
                        data:{ "flag": 0,
                         "jobnumber":row.jobnumber},
                        }) .then(res=>{
                    this.$axios.get("/s/backend/api/api/user_list", {
                            params: { 'accessToken':localStorage.getItem('token'),
                        page :1,
                        limit :20,
                        roleid :8  }
                            })

                        .then(res=>{
                        
                            this.tableData = res.data.data
                    
                            })
                            .catch(function(){
                            
                            })
                            if(res.data.code==0){

                            }else{
                        this.$message({
                   message:res.data.msg,
                  type: 'success'
                     });
                            }
                                
                        })
        .catch(function(){
           
        })  
          },
       },
        mounted(){

           
this.$axios.get("/s/backend/api/arealist")

     .then(res=>{
    
        console.log(res.data)
        this.options = res.data.data
 
        })
        .catch(function(){
           
        })
            //选择职位
this.$axios.get("/s/backend/api/api/user_list", {
        params: { 'accessToken':localStorage.getItem('token'),
       page :1,
       limit :20,
       roleid :2 ,
        }
        })

     .then(res=>{
    
         this.tableData = res.data.data
 
        })
        .catch(function(){
           
        })

  

      
      
      this.$axios.get("/s/backend/api/api/user_detail", {
        params: { 'accessToken':localStorage.getItem('token'),
        'jobnumber':localStorage.getItem('jobnumber')
        }
        })

     .then(res=>{
          this.info = res.data.data
           
        })
        .catch(function(){
           
        })
        }
}
</script>

<style scoped>
.red{
    color: red;
}
/deep/.el-dialog__wrapper{
    top:-50px
}

/deep/.kk .el-dialog{
        width: 25%;

    float: right;
  
}





.actives{
    background: #0034FF  !important;
    color: #fff  !important ;
}

.item li:hover{
    color: #0034FF;
}
.item li a:hover{
    color: #0034FF !important;
}
   /deep/.el-table th > .cell {
      text-align: center;

    }

    /deep/.el-table .cell {
      text-align: center;

    }

/deep/.el-tabs--card>.el-tabs__header{
    border-bottom: none !important;
}

/deep/ .el-tabs--card>.el-tabs__header .el-tabs__item{
    border-bottom:1px solid #E4E7ED;}
/deep/ .el-tabs__item.is-active{
    border:1px solid #409EFF;
    border-left:1px solid #409EFF !important;
      border-bottom:1px solid #409EFF !important;
}
    .header{
            min-height: 57px;
            border-bottom: 1px solid #f5f5f5;
        display: flex;
        align-items: center;
        width: 100%;
        justify-content: space-between;
    }
    .header .left_menu{
        display: flex;   width: 100%;  padding:0 40px;
        justify-content: space-between;
    }
    .header .left_menu .item{
        display: flex;
        align-items: center;
    }
        .header .left_menu .item .a{
            margin-right: 60px;  
            cursor: pointer;
        }
           .header .left_menu .item li a{
               text-decoration: none;color: #000;font-size: 16px;
font-weight: 500;
           }

        .header .active{
            color: #0034FF;
            position:relative;
        }
             .header .active::before{
                      content: "";
   
         left: 0;
    position: absolute;
    bottom:-130%;
    height:3px;
    width:30px;
    background: #0034FF;
            }
            /deep/.el-radio-button__inner{
                padding:8px 15px;
                border-radius: 0;
            }
            /deep/.el-radio-button__orig-radio:checked+.el-radio-button__inner{


                background: #0034FF;
            }

            .list{
                display: flex;
            }
            .list p {
                padding:8px 15px;
               background: #FFFFFF;font-size: 14px;
border-radius: 4px 0px 0px 0px; cursor: pointer;
            }
</style>